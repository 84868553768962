.page-yachts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.page-yachts-catalog {
  width: 475px;
  height: 387px;
  margin: 0 0 15px 0;
}

.page-yachts-catalog-last {
  width: 475px;
  height: 387px;
  margin: 0 0 15px 0;
}

.page-yacht-picture {
  width: 100%;
  height: 316px;
}

.page-yacht-picture img {
  width: 100%;
  height: 316px;
}