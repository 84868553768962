.pagination {
  width: 100%;
  height: 40px;
  text-align: right;
  font-family: "Magistral-Medium";
}

.pagination a {
  display: inline-block;
  padding: 0 10px;
  text-decoration: none;
  color: var(--header-bg);
}