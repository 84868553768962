.footer-left {
    display: flex;
    width: 100%;
    background-color: var(--header-bg);
    font-family: "Magistral-Light";
    color: var(--menu-color);
}

.footer-wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

.map {
    width: 300px;
    height: 200px;
    border-right: 2px solid white;
}

.disc__item {
    text-align: center;
    padding: 10px 10px 0 10px;
}

.disc__item:first-child {
    padding-top: 20px;
}

.disc__item:last-child {
    padding-bottom: 10px;
}

.disc__item a {
    color: var(--menu-color);
}

.line-2 {
    width: 80%;
    height: 1px;
    background-color: var(--menu-color);
}

.messengers {
    width: 40%;
    font-family: "Magistral-BookItalic";
    color: var(--menu-color);
    font-size: 30px;
    padding: 15px 10px 10px 10px;
    justify-content:space-evenly;
    display: flex;
    flex-wrap: nowrap;
}

.messengers a {
    color: var(--menu-color);
}

.sign {
    font-size: 15px;
    padding: 0 15px 10px 15px;
    text-align: center;
}

.nae {
    font-size: 10px;
}

.nae a {
    color: var(--menu-color);
}

@media (max-width: 2000px) {
    .map {
        height: 202px;
    }
}

@media (max-width: 650px) {
    .messengers {
        width: 70%;
    }
}

@media (max-width: 607px) {
    .map {
        height: 221px;
    }
}

@media (max-width: 562px) {
    .map {
        height: 240px;
    }
    .messengers {
        width: 100%;
    }
}

@media (max-width: 435px) {
    .map {
        height: 259px;
    }
}

@media (max-width: 365px) {
    .map {
        display: none;
    }
    .messengers {
        width: 70%;
    }
}