.line {
    width: 100%;
    height: 1px;
    background-color: #8e9095;
    margin: 30px 0 30px 0;
}

.left {
    display: inline-block;
    width: 70%;
    vertical-align: top;
    text-align: justify;
}

.left-text {
    font-family: "Magistral-Medium";
    font-size: 20px;
    color: var(--font-gray);
    padding: 0 15px 15px 0;
}

.left-text-bot {
    margin: 0;
    font-family: "Magistral-Medium";
    color: var(--font-gray);
    font-size: 14px;
    padding: 0 15px 5px 0;
}

.left-text p {
    padding: 0 15px 5px 0;
}

.hidden_div {
    display: none;
    font-family: "Magistral-Medium";
    color: var(--font-gray);
    font-size: 14px;
}

.more {
    font-family: "Magistral-Medium";
    color: var(--font-gray);
}

.more a {
    text-decoration: none;
    color: var(--font-gray);
}

.more a:visited {
    color: var(--font-gray);
}

.hidden_div a {
    text-decoration: none;
    color: var(--font-gray);
}

.hidden_div a:visited {
    color: var(--font-gray);
}

.right {
    display: inline-block;
    width: 29.5%;
    vertical-align: top;
    padding-bottom: 10px;
}

.right-text {
    font-family: "Magistral-Medium";
    padding: 0 5px 5px 0;
}

.hidden_div p {
    padding: 0 15px 5px 0;
}

.hidden_div p:last-child {
    padding-bottom: 10px;
}


@media (max-width: 1000px) {
    .left-text {
        padding-left: 5px;
    }

    .left-text-bot {
        padding-left: 5px;
    }

    .more {
        padding-left: 5px;
    }

    .hidden_div {
        padding-left: 5px;
    }


}

@media (max-width: 650px) {
    .right {
        display: none;
    }

    .left {
        width: 100%;
        text-align: justify;
    }
}