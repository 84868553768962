@import "./reset.css";

@import url('https://cdn-uicons.flaticon.com/2.2.0/uicons-brands/css/uicons-brands.css');


@font-face {
  font-family: "Magistral-Light";
  src: url(/src/media/fonts/Magistral-Light.ttf)
}

@font-face {
  font-family: "Magistral-BoldItalic";
  src: url(/src/media/fonts/Magistral-BoldItalic.ttf);
}

@font-face {
  font-family: "Magistral-Medium";
  src: url(/src/media/fonts/Magistral-Medium.ttf);
}

@font-face {
  font-family: "Magistral-BookItalic";
  src: url(/src/media/fonts/Magistral-BookItalic.ttf);
}

:root {
  --darkgray: #33353d;
  --gray: #adb0b2;
  --white: #ffffff;
  --lightgray: #8e9095;
  --sdlightgray: #D6D7D8;
  --darkwhite: #F3F4F6;
  --black : #000000;
  --transp-gr: rgba(220,220,220, 1);

  --header-bg: var(--darkgray);
  --phone-color: var(--gray);
  --menu-color: var(--white);
  --font-gray: var(--lightgray);
  --footer-bg: var(--sdlightgray);
  --table-white: var(--darkwhite);

  --page-bg: var(--white);
  --burger-bg: var(--transp-gr);
}



.App {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0px 0px;
  background: var(--page-bg);
}