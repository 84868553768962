.open {
  display: flex !important;
}

.header {
  padding: 20px 0;
  background-color: var(--header-bg);
  color: var(--menu-color);
  border-bottom: 1px solid;

}

.rest {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 850px;
}

.nav-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 30px;
  row-gap: 40px;
}

.nav-right {
  text-align: end;
}

.logo {
  color: var(--menu-color);
  font-size: 20px;
  font-family: "Magistral-BoldItalic";
}

.logo strong {
  font-weight: 700;
}

.tel {
  font-family: "Magistral-Light";
  color: var(--phone-color);
  padding-bottom: 10px;
}

.tel a {
  color: var(--phone-color);
}

.menu {
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Magistral-BoldItalic"
}

.menu a {
  color: var(--menu-color);
}

.burger {
  display: none;
  position: relative;
  z-index: 50;
  align-items: center;
  justify-content: flex-end;
  width: 30px;
  height: 18px;
}

.burger span {
  height: 2px;
  width: 80px;
  transform: scale(1);
  background-color: var(--menu-color);
}

.burger::before,
.burger::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: var(--menu-color);
  transition: all 0.3 ease 0s;
}

.burger::before {
  top: 0;
}

.burger::after {
  bottom: 0;
}

.burger.active span {
  transform: scale(0);
}

.burger.active::before {
  top: 50%;
  transform: rotate(-45deg) translate(0, -50%);
  transition: transform 0.4s ease;
}

.burger.active::after {
  bottom: 50%;
  transform: rotate(45deg) translate(0, -50%);
  transition: transform 0.4s ease;
}

@media (max-width: 650px) {

  .nav-right {
      color: var(--header-bg);
      display: none;
      flex-direction: column;
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 50;
      overflow-y: auto;
      padding: 50px 0px;
      background-color: var(--transp-gr);
      animation: burgerAnimation 0.4s;
  }

  .menu a {
    color: var(--header-bg)
  }

  .menu {
      flex-direction: column;
      row-gap: 50px;
      font-size: 18px;
  }

  .nav-row {
      column-gap: 20px;
  }

  .logo {
      font-size: 16px;
  }

  .tel {
      text-align: center;
      padding-top: 30px;
      padding-bottom: 60px;
      font-size: 22px;
      color: var(--header-bg);
  }

  .tel a {
    color: var(--header-bg);
  }

  .burger {
      display: flex;
  }

  @keyframes burgerAnimation {
      from {
          opacity: 0;
      }

      to {
          opacity: 1;
      }
  }
  
}