.char {
    font-family: "Magistral-Medium";
    color: var(--font-gray);
    margin: 0;
    display: inline-block;
    padding-right: 20px;
    vertical-align: top;
    text-align: center;
}

.char p {
    margin: 0;
    font-size: 30px;
    padding: 10px 10px 10px;
}

.char p:first-child {
    font-size: 14px;
    padding: 10px 10px 10px;
}

table {
    width: 100%;
    padding-top: 30px;
    font-family: "Magistral-Light";
    color: var(--font-gray);
}

.tr-g {
    background-color: var(--table-white);
}

.td {
    padding: 7px 10px;
    vertical-align: middle;
}

.td:first-child {
    width: 50%;
}

.tube {
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

iframe {
    width: 700px;
    height: 400px;
}