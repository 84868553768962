.catering__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    font-family: "Magistral-Medium";
    color: var(--header-bg);
    height: auto;
}

.catering {
    width: 80%;
    min-height: 40vh;
    max-width: 800px;
    padding: 10px;
    text-align: center;
    background-color: var(--footer-bg);
}

.catering p {
    padding: 5px;
    padding-top: 10px;
}

.adv__list {
    font-family: "Magistral-Light";
    padding-top: 20px;
}

.adv__list li{
    padding: 5px;
    
}

.ro {
    font-style: italic;
    font-weight: 600;
    padding: 0 5px;
}

.ro1 {
    font-family: "Magistral-Medium";
    padding: 5px;
    padding-top: 10px;
    color: var(--header-bg);
    text-decoration: none;
}