.featured {
  font-family: "Magistral-Medium";
  color: var(--font-gray);
  font-size: 30px;
  padding: 20px 0 5px 0;
}

@media (max-width: 1000px) {
  .featured {
    padding-left: 5px;
  }
}

