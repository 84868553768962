.catalog {
    width: 235px;
    height: 210px;
    margin: 0 20px 15px 0;
}

.catalogpicture img{
    width: 100%;
    height: 160px;
}

.catalogyachtname {
    font-family: "Magistral-Medium";
    font-size: 20px;
    color: var(--header-bg);
    margin-top: 15px;
}

.catalogyachtprice {
    font-family: "Magistral-Light";
    font-size: 16px;
    color: var(--font-gray);
    margin-top: 5px;
}

@media (max-width: 1000px) {
    .catalogyachtname {
        padding-left: 5px;
    }
    .catalogyachtprice {
        padding-left: 5px;
    }
}