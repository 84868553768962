.swiper {
    width: 100%;
}



.swiperyachts__img {
    width: 1000px;
    height: 666px;
}

.swiper-button-prev::after,
.swiper-button-next::after {
    color: var(--menu-color);
    font-size: 40px;
}


@media (max-width: 1000px) {
    .swiperyachts__img {
        width: auto;
        height: auto;
    }
}